<template>
  <section
    id="point"
    class="container"
  >
    <div
      v-if="noSession"
      class="text-center mt-5 pt-2"
    >
      <h3>Oops, Anda belum login. <br>Silahkan login terlebih dahulu</h3>
      <base-btn
        style="width:100%; margin-top: 25px"
        @click="handleClickSignIn"
      >
        Sign In With Google
      </base-btn>
    </div>
    <div v-else>
      <div class="d-flex justify-space-between">
        <div>
          <h3>Hai, {{ this.user.name }}</h3> <span>Point anda <strong>{{ this.user.point }}</strong></span>
        </div>
        <div>
          <v-btn
            class="ml-2"
            fab
            dark
            small
            color="red"
            @click="handleClickSignOut"
          >
            <v-icon dark>
              mdi-exit-to-app
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="mt-2" />
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="basil"
        grow
      >
        <v-tab>
          Claim History
        </v-tab>
        <v-tab>
          Reedem History
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item class="pt-3">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in pointHistory"
                  :key="item.id"
                >
                  <td>{{ format_date(item.created_at) }}</td>
                  <td>{{ item.description }}</td>
                  <td style="width: 20%; text-align:center">
                    <span v-if="item.type == 'in'">+</span> {{ item.amount }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
        <v-tab-item>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in redeemHistory"
                  :key="item.id"
                  @click="detailRedeem(item.id)"
                >
                  <td>{{ format_date(item.created_at) }}</td>
                  <td>{{ item.description }}</td>
                  <td style="width: 20%; text-align:center">
                    <span v-if="item.type == 'in'">+</span> {{ item.amount }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <div
      v-if="!noSession"
      id="footer-redeem"
    >
      <v-dialog
        v-model="dialog"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <base-btn
            class="mt-2"
            style="width:100%"
            v-bind="attrs"
            v-on="on"
          >
            Tukarkan Point Saya
          </base-btn>
        </template>

        <v-card>
          <v-card-title class="headline grey lighten-2">
            <h5>Redeem My Point</h5>
          </v-card-title>
          <v-card-text class="pt-5">
            <v-text-field
              v-model="dataRedeem.phone"
              label="Nomor Handphone"
              outlined
              type="number"
            />

            <v-text-field
              v-model="dataRedeem.amount"
              label="Jumlah poin akan ditukar"
              :rules="rules.amountInput"
              type="number"
              outlined
            />
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              @click="postRedeem"
            >
              Reedem
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </section>
</template>
<script>
  import axios from 'axios'
  import moment from 'moment'
  export default {

    name: 'Point',

    data () {
      return {
        userId: null,
        noSession: false,
        isInit: false,
        isSignIn: false,
        tab: null,
        dialog: false,
        user: {
          name: '',
          emai: '',
          phone: '',
          point: '',
        },
        dataRedeem: {
          phone: '',
          userId: '',
          amount: '',
        },
        pointHistory: [],
        redeemHistory: [],
        rules: {
          amountInput: value => value <= this.user.point || 'Poin tidak mencukupi',
        },
      }
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
    created () {
      const that = this
      const checkGauthLoad = setInterval(function () {
        that.isInit = that.$gAuth.isInit
        that.isSignIn = that.$gAuth.isAuthorized
        if (that.isInit) clearInterval(checkGauthLoad)
      }, 1000)
    },

    mounted: function () {
      this.userId = localStorage.userId
      if (this.userId == null) {
        this.noSession = true
      } else {
        this.dataRedeem.userId = this.userId
      }
      this.getUser()
    },

    methods: {
      getUser: function () {
        // axios.get('http://localhost:8000/api/qr/user/'+this.userId).then(response  => {
        axios.get('https://backoffice.fitsea.co.id/api/qr/user/' + this.userId).then(response => {
          this.user.name = response.data.fullname
          this.user.email = response.data.email
          this.user.phone = response.data.phone
          this.user.point = response.data.point
          this.pointHistory = response.data.pointHistory
          this.redeemHistory = response.data.redeemHistory
          this.dataRedeem.amount = this.user.point
          this.dataRedeem.phone = this.user.phone
        })
      },

      format_date (value) {
        if (value) {
          return moment(String(value)).format('DD MMM YYYY, HH:mm')
        }
      },

      postRedeem: function () {
        if (this.dataRedeem.phone == '') {
          alert('Nomor Handphone tidak boleh kosong')
          return false
        }
        if (this.dataRedeem.amount > this.user.point) {
          alert('Point tidak cukup')
          return false
        }
        if (this.dataRedeem.amount < 1) {
          alert('Setidaknya anda harus menukar 1 point')
          return false
        }

        this.dialog = false
        // axios.post('http://localhost:8000/api/qr/reward/redeem', this.dataRedeem).then(response => {
        axios.post('https://backoffice.fitsea.co.id/api/qr/reward/redeem', this.dataRedeem).then(response => {
          this.$router.push('/redeem?id=' + response.data.id)
        })
      },

      detailRedeem: function (id) {
        this.$router.push('redeem?id=' + id)
      },

      async handleClickUpdateScope () {
        const option = new window.gapi.auth2.SigninOptionsBuilder()
        option.setScope('email https://www.googleapis.com/auth/drive.file')
        const googleUser = this.$gAuth.GoogleAuth.currentUser.get()
        try {
          await googleUser.grant(option)
          console.log('success')
        } catch (error) {
          console.error(error)
        }
      },
      handleClickLogin () {
        this.$gAuth
          .getAuthCode()
          .then((authCode) => {
            // on success
            console.log('authCode', authCode)
          })
          .catch((error) => {
            console.error(error)
          })
      },
      async handleClickSignIn () {
        try {
          const googleUser = await this.$gAuth.signIn()
          if (!googleUser) {
            return null
          }

          this.authToBackend(googleUser.getAuthResponse().access_token)
          this.isSignIn = this.$gAuth.isAuthorized
        } catch (error) {
          // on fail do something
          console.error(error)
          return null
        }
      },
      async handleClickSignOut () {
        try {
          await this.$gAuth.signOut()
          localStorage.clear()
          this.$router.push('/')
        } catch (error) {
          console.error(error)
        }
      },
      handleClickDisconnect () {
        window.location.href = `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${window.location.href}`
      },
      authToBackend (accessToken) {
        // axios.post('http://localhost:8000/api/google/callback', {
        axios.post('https://backoffice.fitsea.co.id/api/google/callback', {
          accessToken: accessToken,
        })
          .then(response => {
            if (response.data.valid == true) {
              this.saveToLocal(response.data)
            }
          })

        // handle QR Reward
        this.getLocation()
      },
      saveToLocal (data) {
        localStorage.userId = data.userId
        localStorage.token = data.token
        location.reload()
      },
    },
  }
</script>
<style scoped>
#footer-redeem {
  position: fixed;
  bottom: 0;
  z-index: 6;
  width: 100%;
  left: 0;
}
</style>
